import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import Img from 'gatsby-image'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { HowToStepData } from '../../../../hooks/useHowTo'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import TextMarkdown from '../../../customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(12)}px 0px`,
      margin: `0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`,
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      margin: `0px ${theme.spacing(7)}px 0px ${theme.spacing(7)}px`,
      padding: `${theme.spacing(12)}px ${theme.spacing(2)}px`,
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('lg')]: {
      margin: `0px ${theme.spacing(15)}px 0px ${theme.spacing(15)}px`,
      padding: `${theme.spacing(12)}px ${theme.spacing(2)}px`,
    },
  },

  description: {
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
    },
  },

  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  jusitfy: {
    textAlign: 'justify',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
}))

interface HowToStepProps {
  isReverse: boolean
  data: HowToStepData
}

const HowToStep: FC<HowToStepProps> = props => {
  const { isReverse, data } = props

  const {
    container,
    description,
    imageContainer,
    jusitfy,
    upperCase,
  } = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const title = getInternationalizedValue(data, 'title', language)
  const descriptionTxt = getInternationalizedValue(
    data,
    'description',
    language,
  )
  const banner_image = data.banner_image

  return (
    <Box
      className={container}
      flexDirection={isReverse ? 'row-reverse' : 'row'}
    >
      <Box>
        <Typography variant="h4" className={upperCase}>
          {title}
        </Typography>
        <Box paddingY={2} className={description}>
          <Typography variant="body1" className={jusitfy}>
            <TextMarkdown text={descriptionTxt} />
          </Typography>
        </Box>
      </Box>

      <Box className={imageContainer}>
        {banner_image ? (
          <Img fixed={banner_image.childImageSharp.fixed} alt="step image" />
        ) : (
          <>&nbsp;</>
        )}
      </Box>
    </Box>
  )
}

export default HowToStep
