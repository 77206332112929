import { graphql, useStaticQuery } from 'gatsby'
import { GraphqlFixedImage } from '../interfaces/graphql'

export interface HowItWorkData {
  id: string
  title_fr: string
  description_fr: string
  txt_link_fr: string
  features_image: GraphqlFixedImage | null
}

export interface HowToParagraphData {
  id: string
  title_fr: string
  description_fr: string
  txt_link_fr: string
}

export interface HowToStepData {
  id: string
  title_fr: string
  description_fr: string
  banner_image: GraphqlFixedImage | null
}

export interface HowToData {
  HowItWork: HowItWorkData
  prerequisite: HowToParagraphData
  Steps: HowToStepData[]
  additional_information: HowToParagraphData
}

export default (): HowToData => {
  const data = useStaticQuery(graphql`
    {
      strapiHowItWorks {
        HowItWork {
          id
          title_fr
          description_fr
          txt_link_fr
          features_image {
            childImageSharp {
              id
              fixed(width: 350) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        prerequisite {
          id
          title_fr
          description_fr
          txt_link_fr
        }
        Steps {
          id
          title_fr
          description_fr
          banner_image {
            childImageSharp {
              id
              fixed(width: 350) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        additional_information {
          id
          title_fr
          description_fr
          txt_link_fr
        }
      }
    }
  `)

  return data.strapiHowItWorks
}
