import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core'
import { Link as GatsbyLink } from 'gatsby'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { HowToParagraphData } from '../../../../hooks/useHowTo'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import TextMarkdown from '../../../customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(14),
    paddingRight: theme.spacing(14),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },

  description: {
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
    },
  },

  centerText: {
    '& span': {
      textAlign: 'center',
    },
  },
}))

interface HowToParagraphProps {
  buttonLink: string
  data: HowToParagraphData
}

const HowToParagraph: FC<HowToParagraphProps> = props => {
  const { buttonLink, data } = props

  const { container, description, centerText } = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const title = getInternationalizedValue(data, 'title', language)
  const descriptionTxt = getInternationalizedValue(
    data,
    'description',
    language,
  )
  const button_text = getInternationalizedValue(data, 'txt_link', language)

  return (
    <Box className={container}>
      <Box>
        <Box textAlign="center">
          <Typography variant="h4">{title}</Typography>
        </Box>
        <Box paddingY={2} className={description}>
          <Typography variant="body1" className={centerText}>
            <TextMarkdown text={descriptionTxt} />
          </Typography>
        </Box>
        {button_text.trim().length ? (
          <Box textAlign="center">
            <Button
              component={GatsbyLink}
              to={buttonLink}
              variant="contained"
              color="primary"
            >
              {button_text}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default HowToParagraph
