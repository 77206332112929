import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import HeaderBanner from '../components/advertising/headerBanner'
import HowItWorks from '../components/pages/howTo/howItWorks'
import HowToParagraph from '../components/pages/howTo/howToParagraph'
import HowToStep from '../components/pages/howTo/howToStep'
import InfoComplementaire from '../components/pages/howTo/infoComplementaire'
import SEO from '../components/seo'
import useHowTo, { HowToStepData } from '../hooks/useHowTo'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
    },
  },
}))

const HowTo = () => {
  const classes = useStyles()

  const data = useHowTo()
  const { HowItWork, prerequisite, Steps, additional_information } = data

  return (
    <>
      <SEO title="Comment çà marche" />
      <Box className={classes.container}>
        <HowItWorks data={HowItWork} />
        <HeaderBanner isTop={false} />
        <HowToParagraph buttonLink="/documentsLegalForm" data={prerequisite} />
        {Steps.map((s: HowToStepData, index: number) => (
          <HowToStep
            key={`step-${s.id}`}
            isReverse={index % 2 === 0}
            data={s}
          />
        ))}

        <InfoComplementaire data={additional_information} />
      </Box>
    </>
  )
}

export default HowTo
