import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core'
import { Link as GatsbyLink } from 'gatsby'
import Img from 'gatsby-image'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import CONFIG from '../../../../config'
import { HowItWorkData } from '../../../../hooks/useHowTo'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import TextMarkdown from '../../../customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(12)}px 0px`,
      margin: `0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`,
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      margin: `0px ${theme.spacing(7)}px 0px ${theme.spacing(7)}px`,
      padding: `${theme.spacing(12)}px ${theme.spacing(2)}px`,
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('lg')]: {
      margin: `0px ${theme.spacing(15)}px 0px ${theme.spacing(15)}px`,
      padding: `0px ${theme.spacing(2)}px`,
    },
  },

  description: {
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
    },
  },

  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  jusitfy: {
    textAlign: 'justify',
  },

  linkButton: {
    padding: theme.spacing(1.5),
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    textDecoration: 'none',
    fontSize: 16,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
}))

interface HowItWorksProps {
  data: HowItWorkData
}

const HowItWorks: FC<HowItWorksProps> = props => {
  const { data } = props

  const {
    container,
    description,
    imageContainer,
    jusitfy,
    linkButton,
  } = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const title = getInternationalizedValue(data, 'title', language)
  const descriptionTxt = getInternationalizedValue(
    data,
    'description',
    language,
  )
  const button_text = getInternationalizedValue(data, 'txt_link', language)
  const features_image = data.features_image

  return (
    <Box className={container}>
      <Box>
        <Typography variant="h4">{title}</Typography>
        <Box paddingY={2} className={description}>
          <Typography variant="body1" className={jusitfy}>
            <TextMarkdown text={descriptionTxt} />
          </Typography>
        </Box>

        <Box paddingTop={3} paddingBottom={2}>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.open(CONFIG.clientSpaceUrl)
            }}
          >
            {button_text}
          </Button> */}
          <a href={CONFIG.clientSpaceUrl} target="blank" className={linkButton}>
            {button_text}
          </a>
        </Box>
      </Box>

      <Box className={imageContainer}>
        {features_image ? (
          <Img
            fixed={features_image.childImageSharp.fixed}
            alt="banner image"
          />
        ) : (
          <>&nbsp;</>
        )}
      </Box>
    </Box>
  )
}

export default HowItWorks
